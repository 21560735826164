import { Whitelabel } from "../helpers/whitelabel";

export const Header = () => <>
	<header id="header" className="header-effect-shrink"
			data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 70}">
		<div className="header-body border-top-0">
			<div className="header-container container">
				<div className="header-row">
					<div className="header-column">
						<div className="header-row">
							<div className="header-logo">
								<a href="/">
									<img src={Whitelabel.logo} alt="" width="200" height="48"
										 data-sticky-width="182"
										 data-sticky-height="40"/>
								</a>
							</div>
						</div>
					</div>
					<div className="header-column justify-content-end">
						<div className="header-row">
							<div
								className="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">
								<div
									className="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
									<nav className="collapse">
										<ul className="nav nav-pills" id="mainNav">
											<li className="dropdown"><a href="/">Home</a></li>
											<li className="dropdown"><a href="/get-started">Get Started</a></li>
											<li className="dropdown"><a href="/agents">Agents & Brokers</a></li>
											<li className="dropdown"><a className="active" href="/login">Login</a></li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</>;
