import { setTheme, Whitelabel } from "./helpers/whitelabel";
import { Header } from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Agents from "./pages/Agents";
import Login from "./pages/Login";
import GetStarted from "./pages/GetStarted";
import Schedule from "./pages/Schedule";
import { Footer } from "./components/Footer";

//setTheme('NECSEMAHealthcare')
export const App = () => {
	if(process.env.NODE_ENV === 'development') {
		let params = (new URL(document.location)).searchParams;
		let theme = params.get("theme");
		setTheme(theme);
	} else {
		setTheme(process.env.REACT_APP_THEME);
	}
	console.log(process.env.NODE_ENV, Whitelabel)
	
	return <div className={`body ${Whitelabel.theme}`}>
		<Header/>
		<Routes>
			<Route path={"/"} element={<Home/>}/>
			<Route path={"/agents"} element={<Agents/>}/>
			<Route path={"/login"} element={<Login/>}/>
			<Route path={"/get-started"} element={<GetStarted/>}/>
			<Route path={"/schedule"} element={<Schedule/>}/>
			<Route path="*" element={<div>404</div>}/>
		</Routes>
		<Footer/>
	</div>;
};
