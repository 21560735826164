import { Helmet } from "react-helmet";

export const Footer = () => <>
	<footer id="footer">
		<div className="container">
			<div className="footer-ribbon">
				<span>About Us</span>
			</div>
			<div className="row py-5 my-4">
				<div className="col-md-6 mb-4 mb-lg-0 ">
					<h5 className="text-3 mb-3">ABOUT MYHEALTHILY</h5>
					<p className="mt-2 mb-2 footer-text-color">MyHealthily is here to provide healthcare solutions and ancillary products to all. Through technological innovation and creative plan design, MyHealthily is bringing healthcare rates traditionally enjoyed only by Fortune 500 companies to small businesses like yours across the country.</p>
					<p className="mb-0"><a href="https://myhealthily.com/" target="_new"
					 className="btn-flat btn-xs text-color-light"><strong
					className="text-2">LEARN MORE</strong><i className="fas fa-angle-right p-relative top-1 ps-2"></i></a></p>
				</div>
				<div className="col-md-6">
					<h5 className="text-3 mb-3">Contact Us</h5>
					<div className="row">
						<div className="col-md-6">
							<ul className="list list-icons list-icons-lg">
								<li className="mb-1"><i className="fab fa-whatsapp text-color-primary"></i><p
									className="m-0"><a
									href="tel:8001234567"><span
									className="footer-text-color">(888) 272-1513</span></a></p></li>
								<li className="mb-1"><i className="far fa-envelope text-color-primary"></i><p
									className="m-0"><a
									href="mailto:support@my%68ealth%69ly.com"><span
									className="footer-text-color">support@myhealthily.com</span></a></p></li>
								<li className="mb-1"><i className="far fa-dot-circle text-color-primary"></i><p
									className="m-0 footer-text-color">25 Rockwood Place, Suite 210<br/>Englewood,
									NJ 07631</p></li>
							</ul>
						</div>
						<div className="col-md-6">
							<ul className="list list-icons list-icons-sm">
								<li><i className="fas fa-angle-right"></i> <a href="get-started" className="link-hover-style-1 ms-1"><span
									className="footer-text-color">Get Started</span></a></li>
								<li><i className="fas fa-angle-right"></i> <a
									href="https://myhealthily.com/privacy-policy.html" target="_new" className="link-hover-style-1 ms-1"><span
									className="footer-text-color">Privacy-Policy</span></a></li>
								<li><i className="fas fa-angle-right"></i> <a
									href="https://myhealthily.com/compliance-legal.html" target="_new" className="link-hover-style-1 ms-1"><span
									className="footer-text-color">Compliance and Legal</span></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="footer-copyright footer-copyright-style-2">
			<div className="container py-2">
				<div className="row py-4">
					<div className="col d-flex align-items-center justify-content-center">
						<p>&copy; 2020 - {new Date().getFullYear()}, <a href="https://myhealthily.com/" target="_new"><span className="footer-text-color">MyHealthily Insurance Solutions, LLC</span></a></p>
					</div>
				</div>
			</div>
		</div>
	</footer>
	<Helmet>
		<script src={`${process.env.PUBLIC_URL}js/custom.js`}></script>
		<script src={`${process.env.PUBLIC_URL}js/theme.init.js`}></script>
	</Helmet>
</>;
