import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Whitelabel } from "../helpers/whitelabel";

function Login() {
	useEffect(() => {
		setTitle(`Login - ${Whitelabel.name}`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Login</h1>
						</div>
					</div>
				</div>
			</section>
			
			<div className="container container-xl-custom py-1 my-1">
				<div className="row justify-content-center">
					<div className="col-xl-9 text-center">
						<h2 className="font-weight-bold text-11 appear-animation"
							data-appear-animation="fadeInUpShorter">Login or Create an Account</h2>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row featured-boxes featured-boxes-style-4">
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation"
							 data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Have an Account?</h4>
								<p className="mb-0">If you have a password, you can login to your dashboard.</p>
								<a href="https://app.myhealthily.com/account/sign-in" className="btn btn-modern btn-primary btn-effect-1 mt-3">Login</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation"
							 data-appear-animation="fadeIn">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Complete Application</h4>
								<p className="mb-0">Start where you left off to complete your application.</p>
								<a href={`https://app.myhealthily.com/shop/myhealthily?label=${Whitelabel.label}`} className="btn btn-modern btn-primary btn-effect-1 mt-3">Resume</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation"
							 data-appear-animation="fadeIn">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Create an Account</h4>
								<p className="mb-0">Sign up to shop plans, enroll, manage your benefits.</p>
								<a href={`https://app.myhealthily.com/shop/myhealthily?label=${Whitelabel.label}`} className="btn btn-modern btn-primary btn-effect-1 mt-3">Get Started</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Reset Password</h4>
								<p className="mb-0">If you forgot or need to reset your password we can help.</p>
								<a href="https://app.myhealthily.com/account/recover" className="btn btn-modern btn-primary btn-effect-1 mt-3">Reset</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row my-3"></div>
				<div className="row my-5 pb-2">
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-mfb.jpg" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">John Torres</strong><span>Executive Director - Maryland Farm Bureau</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Great Customer Service</h4>
										<p>Just got off the phone with your Broker representative. She was great. Mad
											props to her customer service skills.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-premier-homes.jpg" className="img-fluid rounded-circle"
												 alt=""/>
										</div>
										<p><strong className="font-weight-bold">Michel Saadeh</strong><span>Premier Homes of Naples, LLC</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Took Care of Our Needs</h4>
										<p>Your company stepped up quickly to address our insurance needs in a
											challenging environment and with no time to spare. I appreciate your efforts
											and support and look forward to finalizing the coverage for my family in the
											next few days.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-chamber-of-commerce-cape-coral.jpg"
												 className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Member</strong><span>Chamber of Commerce of Cape Coral</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Big Savings</h4>
										<p>We saw a 25% reduction in premium while moving from a $6,000 deductible to $0.
											We are excited for our members to use this benefit and save like us!</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
