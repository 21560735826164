import { initWhiteLabel } from "./WhiteLebelModel";

const WhiteLabelThemes = {
	default: initWhiteLabel()
		.setName("JewelersHealthcare")
		.setLabel ("jhc")
		.setLogo("logo-jewlers.jpg")
		.setAssocLogo("assn-chambers-jewlers.jpg"),
	
	JewelersHealthcare: initWhiteLabel()
		.setName("JewelersHealthcare")
		.setLabel ("jhc")
		.setLogo("logo-jewlers.jpg")
		.setAssocLogo("assn-chambers-jewlers.jpg"),
	
	DentalOfficeHealthCare: initWhiteLabel()
		.setName("DentalOfficeHealthCare")
		.setLabel ("dohc")
		.setLogo("logo-dental-office.jpg")
		.setAssocLogo("assn-chambers-dental-office.jpg"),
	
	ChambersofFLHealthcare: initWhiteLabel()
		.setName("ChambersofFLHealthcare")
		.setLabel ("facp")
		.setLogo("logo-fl.jpg")
		.setAssocLogo("assn-chambers-fl.jpg"),
	
	RealtyHealthcare: initWhiteLabel()
		.setName("RealtyHealthcare")
		.setLabel ("rhc")
		.setLogo("logo-realty.jpg")
		.setAssocLogo("assn-chambers-realty.jpg"),
	
	FarmBureauHealthcare: initWhiteLabel()
		.setName("FarmBureauHealthcare")
		.setLabel ("fbhc")
		.setLogo("logo-farmers.jpg")
		.setAssocLogo("assn-chambers-farmers.jpg"),
	
	PropertyManagersHealthcare: initWhiteLabel()
		.setName("PropertyManagersHealthcare")
		.setLabel ("pmhc")
		.setLogo("logo-narpm.jpg")
		.setAssocLogo("assn-chambers-narpm.jpg"),
	
	NPRAHealthcare: initWhiteLabel()
		.setName("NPRAHealthcare")
		.setLabel ("nprahc")
		.setLogo("logo-npra.jpg")
		.setAssocLogo("assn-chambers-npra.jpg"),
	
	OHRealtyHealthcare: initWhiteLabel()
		.setName("OHRealtyHealthcare")
		.setLabel ("ohrhc")
		.setLogo("logo-oh.jpg")
		.setAssocLogo("assn-chambers-oh.jpg"),
	
	NAPS360Healthcare: initWhiteLabel()
		.setName("NAPS360Healthcare")
		.setLabel ("naps")
		.setLogo("logo-naps.jpg")
		.setAssocLogo("assn-chambers-naps.jpg"),
	
	ATLBuildersHealthcare: initWhiteLabel()
		.setName("ATLBuildersHealthcare")
		.setLabel ("gahba")
		.setLogo("logo-alt-builders.jpg")
		.setAssocLogo("assn-chambers-alt-builders.jpg"),
	
	VECCSHealthcare: initWhiteLabel()
		.setName("VECCSHealthcare")
		.setLabel ("shop")
		.setLogo("logo-veccs.jpg")
		.setAssocLogo("assn-chambers-veccs.jpg"),
	
	BuildersNKYHealthcare: initWhiteLabel()
		.setName("BuildersNKYHealthcare")
		.setLabel ("shop")
		.setLogo("logo-ky-builders.jpg")
		.setAssocLogo("assn-chambers-ky-builders.jpg"),
	
	ManufacturersHealthcare: initWhiteLabel()
		.setName("ManufacturersHealthcare")
		.setLabel ("mhc")
		.setLogo("logo-manufacturers.jpg")
		.setAssocLogo("assn-chambers-manufacturers.jpg"),
	
	ChambersofVaHealthcare: initWhiteLabel()
		.setName("ChambersofVaHealthcare")
		.setLabel ("vacce")
		.setLogo("logo-va.jpg")
		.setAssocLogo("assn-chambers-va.jpg"),
	
	ChambersofNJHealthcare: initWhiteLabel()
		.setName("ChambersofNJHealthcare")
		.setLabel ("njchc")
		.setLogo("logo-nj.jpg")
		.setAssocLogo("assn-chambers-nj.jpg"),
	
	NECSEMAHealthcare: initWhiteLabel()
		.setName("NECSEMAHealthcare")
		.setLabel ("necsema")
		.setLogo("logo-necsema.jpg")
		.setAssocLogo("assn-chambers-necsema.jpg"),
	
	CraftIndustryHealthcare: initWhiteLabel()
		.setName("CraftIndustryHealthcare")
		.setLabel ("cih")
		.setLogo("logo-craft.jpg")
		.setAssocLogo("assn-chambers-craft.jpg"),
	
	AGIHealthcare: initWhiteLabel()
		.setName("AGIHealthcare")
		.setLabel ("agih")
		.setLogo("logo-agi.jpg")
		.setAssocLogo("assn-chambers-agi.jpg"),
	
	PNHealthcare: initWhiteLabel()
		.setName("PNHealthcare")
		.setLabel ("pnh")
		.setLogo("logo-pn.jpg")
		.setAssocLogo("assn-chambers-pn.jpg"),
	
	NBDAHealthcare: initWhiteLabel()
		.setName("NBDAHealthcare")
		.setLabel ("nbda")
		.setLogo("logo-nbda.jpg")
		.setAssocLogo("assn-chambers-nbda.jpg"),
	
	NPAHealthcare: initWhiteLabel()
		.setName("NPAHealthcare")
		.setLabel ("npa")
		.setLogo("logo-npa.jpg")
		.setAssocLogo("assn-chambers-npa.jpg"),
	
	MajesticResidencesHealthcare: initWhiteLabel()
		.setName("MajesticResidencesHealthcare")
		.setLabel ("mrf")
		.setLogo("logo-majestic.jpg")
		.setAssocLogo("assn-chambers-majestic.jpg"),
	
	DesignersHealthcare: initWhiteLabel()
		.setName("DesignersHealthcare")
		.setLabel ("dhc")
		.setLogo("logo-designers.jpg")
		.setAssocLogo("assn-chambers-designers.jpg"),
	
	FirstRespondersHealthcare: initWhiteLabel()
		.setName("FirstRespondersHealthcare")
		.setLabel ("frhc")
		.setLogo("logo-first-responders.jpg")
		.setAssocLogo("assn-chambers-first-responders.jpg"),
};

export var Whitelabel = WhiteLabelThemes.default;

export function setTheme(theme) {
	if (WhiteLabelThemes[theme]) {
		Whitelabel = WhiteLabelThemes[theme];
	} else {
		Whitelabel = WhiteLabelThemes.default;
	}
	return Whitelabel;
}

