import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Whitelabel } from "../helpers/whitelabel";

function GetStarted() {
	useEffect(() => {
		setTitle(`Get Started - Healthcare, Dental, Vision, and More!`);
	}, []);
	
	return (
		<div role="main" className="main">
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Get Started</h1>
						</div>
					</div>
				</div>
			</section>
			
			<div className="container container-xl-custom py-1 my-1">
				<div className="row justify-content-center">
					<div className="col-xl-9 text-center">
						<h2 className="font-weight-bold text-11 appear-animation"
							data-appear-animation="fadeInUpShorter">Who Are You Looking to Cover?</h2>
						<p className="line-height-9 text-4 opacity-9 appear-animation"
						   data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">Please select the
							shopping experience that best describes you.</p>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row text-center text-md-start pt-4 pb-5 my-4">
					<div className="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter"
						 data-appear-animation-delay="600">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Group Coverage</h2>
						<ul className="list list-icons list-primary">
							<li><i className="fas fa-check"></i> I own a business</li>
							<li><i className="fas fa-check"></i> I employees between 2 – 500+ people</li>
							<li><i className="fas fa-check"></i> I’m looking to get healthcare for my employees</li>
							<li><i className="fas fa-check"></i> I’m looking to get healthcare for myself, my spouse
								and/or children
							</li>
						</ul>
						<a href="/schedule" className="btn btn-modern btn-primary btn-effect-1 mt-32">CONTINUE</a>
					</div>
					<div className="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter"
						 data-appear-animation-delay="1000">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Individual & Family Coverage</h2>
						<ul className="list list-icons list-primary">
							<li><i className="fas fa-check"></i> I am looking to get healthcare for myself, my spouse
								and/or children (none of which are employeed by my business)
							</li>
						</ul>
						<hr/>
						<p>We are currently working with carriers to offer coverage for individuals and families, but until we find a solutions, please consider the following options.</p>
						<h4>Sidecar Health</h4>
						<p>Welcome to modern, common sense health insurance that's clear and simple to use. No more networks, no more referrals, no more games.</p>
						<p><a href="https://sidecarhealth.com/for/myhealthily/" target="_new">Visit Website</a></p>

						<h4>Healthcare.gov</h4>
						<p>Visit Healthcare.gov to find out if you're eligible, learn about essential health benefits, and compare plans.</p>
						<p><a href="https://www.healthcare.gov/" target="_new">Visit Website</a></p>
					</div>
				</div>
			</div>
			
			<div id="examples" className="container py-2">
				<div className="row">
					<div className="col">
						<div className="row">
							<div className="col pb-3">
								<h4>About Your Plan​</h4>
								<div className="alert alert-default">
									You are not joining a group healthcare plan that is part of an association.​ Our
									licensed advisors will help you narrow down the right coverage for you, based on your
									specific needs.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	);
}

export default GetStarted;
